<style scoped>
.button-client-form{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-client-form:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #011c27 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
  .button-view-client{
    border: 2px solid #595959;
    position: relative;
    background-color:transparent !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27 !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .button-view-client:hover{
    border: 2px solid #011c27;
    position: relative;
    background-color:#011c27 !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
  .button-new-client{
    border: 2px solid #247ba0;
    margin-right:30px; 
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .button-new-client:hover{
    border: 2px solid #011c27;
    margin-right:30px; 
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  .btn-group, .btn-group-vertical {
    margin-right: 15px;
    border: 2px solid #595959;
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
}
.btn-group > .btn, .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
    font-weight: 500;
    }
.tbody-table-clients{
  font-family: "Inter", sans-serif;
  color: #011c27;
  font-weight: 500;
}
table th, .table td {
    vertical-align: middle;
}
.id-box-client{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.brand-box-client{
    font-weight: 600;
    background-color: #247ba0;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
.table th, .table td {
    border-bottom: 2px solid #d3d3d3;
}
.table thead th {
    border-bottom: 1px solid #e9e9e9;
    border-top: 1px solid #ffffff;
}
table th, .table td {
    border-top: 1px solid #ffffff;
}
.div-table-clients{
  padding-left: 24px;
  padding-right: 24px;
}
.tr-table-clients{
  font-family: nunito;
  color: #595959;
  font-weight: 600;
}
.card-clients-body{
    background-color: #f9faff;
    border-radius: 30px;
}
.card-clients-body{
    padding: 40px !important;
}

#table-clients > div{
    cursor: pointer;
    background-color: #f9faff;
}
.title-clients {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  padding-left:30px;
  font-family: nunito;
  font-weight: 600;
}
.dot-clients{
  color:#247ba0;
  font-family: nunito;
  font-weight: 600;

}
</style>
<script>
import Layout from "../../layouts/main";
const clientController = require("@/controllers/endpoint/clients");
import Swal from "sweetalert2";
const userController = require("@/controllers/endpoint/user");
import axios from "axios";

/**
 * Checkout Component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      categorias: [], // Agrega un array para almacenar las categorías
      selectedCategoria: null, // Agrega una propiedad para almacenar la categoría seleccionada
      imagenURL: '', 
      imagenCargada: false,
      options_search:[
          { value: 'id', text: 'ID' },
          { value: 'name', text: 'Name' },
        ],
      url_endpoint: process.env.VUE_APP_BASEPATH_ENDPOINT,
      selected_search:'id',
      searchInput:'',
      sort: "ASC",
      user: {},
      spinner: true,
      clients: {},
      brands: {},
      title: "Clients",
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 20, 50],
      filter: null,
      sortBy: "id",
      filterOn: [],
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "description", sortable: false },
        { key: "No. Brands", sortable: false },
        { key: "createdAt", sortable: false, label: "Date" },
        { key: "id", sortable: false, label: "Action" },
      ],
      fields2: [
        { key: "name", sortable: false },
        { key: "description", sortable: false },
        { key: "No. Brands", sortable: false },
        { key: "createdAt", sortable: false, label: "Desde" },
      ],
      nueva_categoria: {
        nombre: "",
        descripcion:	"",
        cantidad: "",
        id_empresa: "",		
      },
      newCLient: {

      },
      editCLient: {
        id: "",
        name: "",
        description: "",
        rol: "",
      },
      empresas: [],
      editar_descuento: {
        nombre: ""
      }
    };
  },
  computed: {
  },

  mounted() {
    this.userData();
    this.getClients();
    this.loadCategorias();
    this.loadEmpresas();
  },
  methods: {
    editarDatos() {
  const formData = new FormData();
  console.log(this.editar_empresa)
  formData.append('nombre', this.editar_descuento.nombre.toUpperCase());
      formData.append('descripcion', this.editar_descuento.descripcion.toUpperCase());
      formData.append('cantidad', this.editar_descuento.cantidad.toUpperCase());
      formData.append('id_empresa', this.editar_descuento.selectedEmpresa);

  // Verifica si se ha seleccionado un nuevo icono
  if (this.$refs.icono.files[0]) {
      formData.append('nombre', this.editar_descuento.nombre.toUpperCase());
      formData.append('descripcion', this.editar_descuento.descripcion.toUpperCase());
      formData.append('cantidad', this.editar_descuento.cantidad);
      formData.append('id_empresa', this.editar_descuento.selectedEmpresa);
      formData.append('icono', this.$refs.icono.files[0]);
  
  }

  // Verifica si se ha seleccionado un nuevo icono antes de enviar los datos al servidor
  if (!formData.has('icono')) {
    alert('No se ha seleccionado un nuevo icono. Los datos se actualizarán sin cambios en el icono.');
  }

  axios.post(`https://membresiaintegra.com/php_api/descuentos/${this.editar_descuento.id}/actualizar`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  .then(response => {
    console.log('Respuesta del servidor:', response.data);
    alert('Datos actualizados con éxito.');
    this.closeModal();
    this.spinner = true;
    this.getClients();
  })
  .catch(error => {
    console.error('Error al actualizar los datos:', error);
    alert('Ocurrió un error al actualizar los datos. Por favor, inténtelo de nuevo.');
  });
},
    getEmpresaNombre(id_empresa) {
      const empresa = this.empresas.find(emp => emp.id === parseInt(id_empresa));
      return empresa ? empresa.nombre : 'Desconocido';
    },
    loadCategorias() {
      axios.get('https://membresiaintegra.com/php_api/empresas')
        .then(response => {
          // Guarda las categorías en el array categorias
          this.categorias = response.data;
        })
        .catch(error => {
          console.error('Error al cargar categorías:', error);
        });
    },
    loadEmpresas() {
      axios.get('https://membresiaintegra.com/php_api/empresas')
        .then(response => {
          // Guarda las categorías en el array categorias
          this.empresas = response.data;
        })
        .catch(error => {
          console.error('Error al cargar empresas:', error);
        });
    },
    // Otros métodos de tu componente...
    cargarImagen(evento) {
      const archivo = evento.target.files[0];
      if (archivo) {
        // Crear un objeto FileReader para leer el archivo
        const lector = new FileReader();
        lector.readAsDataURL(archivo);
        // Cuando la lectura esté completa, actualizar la URL de la imagen
        lector.onload = () => {
          this.imagenURL = lector.result;
          this.nueva_categoria.icono = this.imagenURL
          this.imagenCargada = true;
        };
      }
    },

    // Método para enviar datos al servidor
    enviarDatos() {
      const formData = new FormData();
      formData.append('nombre', this.nueva_categoria.nombre.toUpperCase());
      formData.append('descripcion', this.nueva_categoria.descripcion.toUpperCase());
      formData.append('cantidad', this.nueva_categoria.cantidad);
      formData.append('id_empresa', this.selectedCategoria);
      formData.append('icono', this.$refs.icono.files[0]);

      axios.post('https://membresiaintegra.com/php_api/descuentos/nuevo', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        console.log('Respuesta del servidor:', response.data);
        alert('Datos enviados con éxito.');
        this.closeModal();
        this.spinner = true;
        this.getClients()
      })
      .catch(error => {
        console.error('Error al enviar los datos:', error);
        alert('Ocurrió un error al enviar los datos. Por favor, inténtelo de nuevo.');
      });
    },

    searchChange() {
      if (this.searchInput == '') {
        this.getClients(this.sort);
      } else if (this.searchInput !== '' && this.selected_search == 'id') {
        this.search("id");    
      } else if (this.searchInput !== '' && this.selected_search == 'name') {
        this.search("name");       
      }
    },

    search(type) {
      const dataContent = clientController.search(type, this.searchInput);
      dataContent
        .then((response) => {
          //Succesfully result
          this.clients = response.data;
          console.log(this.clients);
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },

    userData() {
      const dataUser = userController.dataUser();
      dataUser
        .then((response) => {
          //Succesfully result
          this.user = response.data[0];
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },

    getClients() {
      const dataClients = clientController.dataDescuentos(this.sort);
      dataClients
        .then((response) => {
          //Succesfully result
          this.currentPage = 1;
          let clients = response.data;
          this.clients = clients;
          this.totalRows = clients.length;
          setTimeout(() => {
            this.spinner = 0;
          }, "1000");
        })
        .catch((err) => {
          //Error result
          console.error(err);
        });
    },
   
    changeSort(sort) {
      this.sort = sort;
      this.getClients();
    },

    edit(id) {
      const edit = this.clients.find((client) => client.id == id);
      this.editar_descuento.id = edit.id;
      this.editar_descuento.nombre = edit.nombre;
      this.editar_descuento.descripcion = edit.descripcion;
      this.editar_descuento.cantidad = edit.cantidad;
      this.editar_descuento.selectedEmpresa = edit.id_empresa;
      this.$refs["edit-client-modal"].show();
    },

    deleteClient(id) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!\nThe brands related to this client will be delete it too",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            console.log(this.user.rol)
            let data = {
              id: id,
              rol: this.user.rol,
            };
            const deleteClient = clientController.deleteClient(data);
            deleteClient
              .then((response) => {
                //Succesfully result
                response;
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your client has been deleted.",
                  "success"
                );
                this.resetData();
                this.spinner = true;
                this.getClients();
              })
              .catch((err) => {
                //Error result
                console.error(err);
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your client is safe",
              "error"
            );
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    log(client) {
      const n = client.name.replace(/ /g, "-").toLowerCase();
      const link = `/client/${n}/brands/${client.id}`;
      this.$router.push(link);
    },

    addClient() {
      this.nueva_categoria.nombre = this.nueva_categoria.nombre.toUpperCase();
      this.enviarDatos(); // Llamar al método para enviar datos
      // Luego puedes agregar el resto de tu lógica si es necesario
    },

    saveClient() {
      this.editCLient.rol = this.user.rol;
      this.editCLient.name = this.editCLient.name.toUpperCase();
      const saveClient = clientController.update(this.editCLient);
      saveClient
        .then((response) => {
          //Succesfully result
          let data = response;
          this.alert(data, "success");
          this.closeModal();
          this.resetData();
          this.spinner = true;
          this.getClients();
        })
        .catch((err) => {
          this.alert(err, "danger"); //Error result
        });
    },
    deleteCategory(id) {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger ml-2",
    },
    buttonsStyling: false,
  });

  swalWithBootstrapButtons
    .fire({
      title: "¿Estás seguro?",
      text: "¡No podrás revertir esto!",
      icon: "warning",
      confirmButtonText: "Sí, eliminarlo!",
      cancelButtonText: "No, cancelar!",
      showCancelButton: true,
    })
    .then((result) => {
      if (result.value) {
        axios.delete(`https://membresiaintegra.com/php_api/descuentos/${id}/eliminar`)
          .then(() => {
            swalWithBootstrapButtons.fire(
              "¡Eliminado!",
              "Tu descuento ha sido eliminada.",
              "success"
            );
            this.resetData();
            this.spinner = true;
            this.getClients();
          })
          .catch((error) => {
            console.error(error);
            swalWithBootstrapButtons.fire(
              "Error",
              "Ocurrió un error al eliminar el descuento. Por favor, inténtelo de nuevo.",
              "error"
            );
          });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          "Cancelado",
          "Tu descuento está seguro",
          "error"
        );
      }
    });
},

    alert(message, variant) {
      this.$bvToast.toast(`${message}`, {
        title: "CLIENT ALERT",
        variant: variant,
        solid: true,
      });
    },
    closeModal() {
      this.$refs["new-client-modal"].hide();
      this.$refs["edit-client-modal"].hide();
    },
    resetData() {
      this.newCLient = {
        name: "",
        description: "",
        rol: "",
      };
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div  class="col-lg-6 text-lg-left">
          <h5 class="title-clients">Descuento<span class="dot-clients">.</span></h5>
      </div>
      <div class="button-div col-lg-6 text-lg-right">
      <button v-b-modal.client-popup-form class="button-new-client" style="">Agregar descuento</button>
      </div>
      
    </div>
    
    <!--START MODAL-->
    <b-modal
      ref="new-client-modal"
      id="client-popup-form"
      title="Nuevo descuento"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">Nombre descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.nombre"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Descripcion descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.descripcion"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Cantidad descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="nueva_categoria.cantidad"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <label for="categoria">Empresa<span style="color:#247ba0">*</span></label>
          <select v-model="selectedCategoria" class="form-control" id="categoria">
            <option :value="null" disabled>Seleccionar empresa</option>
            <!-- Itera sobre las categorías para mostrarlas en el dropdown -->
            <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nombre }}</option>
          </select>
          <div class="form-group">
            <label style="padding-top:10px" for="logo_url">Subir imagen de descuento<span style="color:#247ba0">*</span></label>
            <br> <input ref="icono" type="file" @change="cargarImagen" accept="image/*">
            <img width="50" height="50" :src="imagenURL" alt="Imagen cargada" v-if="imagenCargada">
          </div>
        </div>
      </div>
      <div style="padding-top:20px" class="row">
        <div class="col-lg-12">
          <div class="text-left">
            <button
              v-on:click="addClient()"
              class="btn button-client-form btn-primary"
            >
              Agregar 
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!--END MODAL-->

    <div v-if="spinner" style="text-align: center; padding-top: 150px">
      <b-spinner
        type="grow"
        class="mt-50"
        style="width: 3rem; height: 3rem; color:#011c27"
        role="status"
      ></b-spinner>
    </div>
    <div v-if="!spinner" class="card-clients fade-in">
      <div class="table-responsive div-table-clients">
     <table class="table mb-0 table-clients">
            <thead class="thead-table-clients">
                    <tr class="tr-table-clients">
                        <th width="2%" style="text-align: center;">ID</th>
                        <th>Nombre</th>
                        <th>Descripcion</th>
                        <th>Cantidad</th>
                        <th>Empresa</th>
                        <th>Imagen</th>
                        <th></th>
                        <th></th>
                    </tr>
            </thead>
            <tbody class="tbody-table-clients">
                    <tr v-for="(client, index) in clients" :key="index">
                      <td><span class="id-box-client">{{client.id}}</span></td>
                      <td><span>{{client.nombre}}</span></td>
                      <td><span>{{client.descripcion}}</span></td>
                      <td><span>{{client.cantidad}}</span></td>
                      <td><span>{{ getEmpresaNombre(client.id_empresa) }}</span></td>
                      <td><img width="50" height="50" :src="`${url_endpoint}/storage/app/public/iconos/descuento/${client.id}/${client.imagen_url}`" alt="Imagen"></td>
                      <td><button v-on:click="edit(client.id)" class="btn button-brands-form btn-primary">EDITAR</button></td>   
                      <td><button @click="deleteCategory(client.id)" class="btn button-brands-form btn-danger">ELIMINAR</button></td>
                    </tr>
            </tbody>
      </table>
    </div>
    </div>
    

    <!--START MODAL2-->
    <b-modal
      ref="edit-client-modal"
      id="edit-client-popup-form"
      title="Editar descuento"
      hide-footer
      centered
      title-class="font-18"
    >
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label for="name">Nombre Descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_descuento.nombre"
              type="text"
              class="form-control"
              id="name"
              placeholder="Ingresa Nombre"
            />
          </div>
        </div>
      </div>
      <div class="form-group">
            <label for="name">Descripcion descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_descuento.descripcion"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <div class="form-group">
            <label for="name">Cantidad descuento<span style="color:#247ba0">*</span></label>
            <input
              v-model="editar_descuento.cantidad"
              type="text"
              class="form-control"
              id="name"
              placeholder=""
            />
          </div>
          <label for="categoria">Empresa<span style="color:#247ba0">*</span></label>
          <select v-model="editar_descuento.selectedEmpresa" class="form-control" id="categoria">
            <option :value="null" disabled>Seleccionar empresa</option>
            <!-- Itera sobre las categorías para mostrarlas en el dropdown -->
            <option v-for="categoria in categorias" :key="categoria.id" :value="categoria.id">{{ categoria.nombre }}</option>
          </select>
          <div class="form-group">
            <label style="padding-top:10px" for="logo_url">Subir imagen de descuento<span style="color:#247ba0">*</span></label>
            <br> <input ref="icono" type="file" @change="cargarImagen" accept="image/*">
            <img width="50" height="50" :src="imagenURL" alt="Imagen cargada" v-if="imagenCargada">
          </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="text-right">
            <button
              v-on:click="editarDatos()"
              class="btn button-client-form btn-primary"
            >
              Guardar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
    <!--END MODAL2-->
  </Layout>
</template>
